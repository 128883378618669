@import '~@salesforce-ux/design-system/scss/_design-tokens.scss';

body {
  background-color: #F3F3F3;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  margin: 0;
}

html {
  height: 100%;
}

.container {
  background-color: #FFFFFF;
  padding: $spacing-medium;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.form-container {
  display: flex;
  flex-direction: row;
  gap: $spacing-medium;
  margin-top: $spacing-medium;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 160px;
}

.review-item-text {
  font-size: 0.875rem;
  padding-top: $spacing-xxx_small;
  padding-bottom: $spacing-xx_small;
  white-space: pre-line;
}
